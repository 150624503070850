import React from 'react';
import '../css/Misc/NotFound.css';

function NotFound() {
  return (
    <h2 className="Not-found-text">
      Page not found!
    </h2>
  );
}

export default NotFound;
