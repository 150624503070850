import React from 'react';
import '../css/Misc/LoadingSpinner.css';

function LoadingSpinner() {
  return (
    <div className="Loading-spinner">
    </div>
  );
}

export default LoadingSpinner;
